import * as React from 'react';
import * as Dialog from '@naan/primitives/modals/dialog';
import { styled } from '@naan/stitches.config';
import { LocaleCode } from '@utils/util-types';
import { AppFactory } from '@app/app-factory';
import { ReturnNavState } from 'components/nav/return-nav-state';
import { FullyStyledMarkdown } from '@naan/primitives/text';
import { Image } from '@naan/primitives/image';
import { GlobeIcon } from '@naan/icons/globe-icon';
import { ChevronRightExtraSmallIcon } from '@naan/icons/chevron-right-icon';

import __, {
  translateWithoutDefault,
  pickLocalizedValue,
} from '@core/lib/localization';

import jwEspanolLogo from '../../../components/branding/jw-espanol-full-logo.png';
import jwEnglishLogo from '../../../components/branding/jw-english-full-logo.png';
// import { appConfig } from '@app/env';

// beware, styling blindly copied from settings-modal
const Container = styled(Dialog.Container, {
  width: 'min( 600px, calc(100% - 32px) )',
});

const logoMap = { en: jwEnglishLogo, es: jwEspanolLogo };
type logoKey = keyof typeof logoMap;

const productBlurb = (l2: LocaleCode) => {
  // switch (l2) {
  //   case 'en':
  //     return __(
  //       'A paragraph of text about Jiveworld English, blah blah [placeholder]',
  //       'productInfoModalBlurb:en'
  //     );
  //   default:
  //     return __(
  //       `A paragraph of text about Jiveworld Espanol and Radio Ambulante, etc [placeholder]`,
  //       'productInfoModalBlurb:es'
  //     );
  // }
  return translateWithoutDefault(`cms:productInfoModalBlurb:${l2}`);
};

const L2SwitcherContainer = styled(Container, {
  width: 'min(100% - 32px, 420px)',
});

const LogoWrapper = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  padding: '40px 0',
  '& img': {
    // width: '100%',
    maxWidth: 204,
  },
});

const Wrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: 24,
  '* > .blurb': {
    textStyle: 'body',
    '& p': {
      marginBottom: '1rem',
    },
  },
});

const L2InfoSwitchModal = ({ onDismiss }: { onDismiss: () => void }) => {
  return (
    <L2SwitcherContainer open onDismiss={onDismiss}>
      {/* <HeadingWrapper>
        <span className="title">{productName}</span>
      </HeadingWrapper> */}

      <Dialog.Body>
        <LogoWrapper>
          <Image src={logoMap[AppFactory.root.l2 as logoKey]} />
        </LogoWrapper>
        <Wrapper>
          <div className="blurb">
            <FullyStyledMarkdown
              source={
                productBlurb(AppFactory.root.l2) +
                '\n\n' +
                __(
                  'Learn more at [jiveworld.com](%{url})',
                  'learnMoreAtJiveworldCom',
                  {
                    url: pickLocalizedValue({
                      en: 'https://www.jiveworld.com/en/',
                      pt: 'https://www.jiveworld.com/pt/',
                    }),
                  }
                )
              }
            />
          </div>
          <SwitchLanguageLink />
        </Wrapper>
      </Dialog.Body>
      <Dialog.ButtonsContainer>
        <Dialog.Button
          presentation="grayLight"
          label={__('Close', 'close')}
          onClick={onDismiss}
        />
      </Dialog.ButtonsContainer>
    </L2SwitcherContainer>
  );
};

const SwitcherWrapper = styled('button', {
  all: 'unset',

  display: 'flex',
  padding: 12,
  gap: 6,
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  alignSelf: 'stretch',
  borderRadius: '$radii$medium',
  border: '1px solid $gray-100',
  textStyle: 'small-text-bold',
  color: '$textSecondary',
  cursor: 'default',

  '&:hover': {
    borderColor: '$teal-200',
    color: '$teal-500',
  },

  '& > .label': {
    textStyle: 'small-text-bold',
  },
  '& > .right-icon': {
    marginLeft: 'auto',
  },
});

const SwitcherButton = ({ label, href }: { label: string; href: string }) => {
  return (
    <SwitcherWrapper
      onClick={() => {
        ReturnNavState.clearL2Cookie();
        window.location.href = href;
      }}
    >
      <div className="left-icon">
        <GlobeIcon />
      </div>
      <div className="label">{label}</div>
      <div className="right-icon">
        <ChevronRightExtraSmallIcon />
      </div>
    </SwitcherWrapper>
  );
};

const SwitchLanguageLink = () => {
  const { l2 } = AppFactory.root;

  return (
    <>
      {l2 === 'es' ? (
        <SwitcherButton
          label="Jiveworld Español (English)"
          href="/welcome?l1=en"
        />
      ) : null}
      {l2 === 'en' ? (
        <SwitcherButton
          label="Jiveworld English (português)"
          href="/welcome?l1=pt"
        />
      ) : null}
    </>
  );
};

export const presentL2InfoSwitchModal = () => {
  AppFactory.dialogPresenter.present(onDismiss => (
    <L2InfoSwitchModal onDismiss={onDismiss} />
  ));
};
